<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="super-list">
      <div class="super-list-tab">
        <div class="super-list-tab-list">
          <div :class="item.id == curTabId ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</div>
        </div>
      </div>
      <div v-if="curTabId == 1">
        <div class="top">
          <Button type="primary" @click="add">{{ $t('codekey_manage_add') }}</Button>
          <!--    <Button type="primary">充值申请</Button>-->
          <div class="search">
            <Input v-model="keyword"
                   :placeholder="$t('common_search_keyword')"
                   clearable
                   search
                   :enter-button="true"
                   @on-search="changeKeyword"
                   style="width: 200px;"
            />
          </div>
        </div>

        <div class="table">
          <Table border :columns="columns" :data="dataList"></Table>
        </div>
        <div class="page">
          <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
        </div>
      </div>
      <div v-if="curTabId == 2">
        <ActivationList :isSuper="true" :isList="true" :dataDeatil="rowDetail"></ActivationList>
      </div>
      <!-- <Modal
          v-model="addCategory"
          :title="$t('codekey_manage_add')"
          width="900px"
      >
        <div>
          <Form ref="formValidate" :model="formItem" :rules="ruleValidate" :label-width="100" >
            <FormItem :label="$t('codekey_manage_add_first_category')">
              <div class="category-list">
                <div :class="formItem.firstCategory == 0 ? 'active' : ''" :key="0 + 'first'" @click="changeFirstCategory(null)">不限</div>
                <div :class="formItem.firstCategory == item.id ? 'active' : ''" v-for="item in firstCategoryList" :key="item.id + 'first'" @click="changeFirstCategory(item)">{{item.name}}</div>
              </div>
            </FormItem>
            <FormItem :label="$t('codekey_manage_add_second_category')" >
              <div class="category-list">
                <div :class="formItem.secondCategory == 0 ? 'active' : ''" :key="0 + 'category'" @click="changeSecondCategory(null)">不限</div>
                <div :class="formItem.secondCategory == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'category'" @click="changeSecondCategory(item)">{{item.name}}</div>
              </div>
            </FormItem>
            <FormItem :label="$t('codekey_manage_add_company')" prop="company">
              <el-select
                  v-model="formItem.company"
                  filterable
                  remote
                  :remote-method="(query) => siteList(query)"
                  style="width:300px;"
                  class="company-h"
              >
                <el-option
                    v-for="item in companyList"
                    :key="item.id"
                    :label="item.name + `(id:${item.id})`"
                    :value="item.id">
                </el-option>
              </el-select>
            </FormItem>
            <FormItem :label="$t('codekey_manage_add_count')" prop="num">
              <Input v-model="formItem.num" type="number" :placeholder="$t('codekey_manage_add_count_placeholder')" style="width: 300px" />
            </FormItem>
            <FormItem :label="$t('codekey_manage_add_timelong')" prop="timelong">
              <Select v-model="formItem.timelong" style="width:300px">
                <Option v-for="item in timelongsList" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </FormItem>
            <FormItem :label="$t('codekey_manage_add_status')" prop="status">
              <Select v-model="formItem.status" style="width:300px">
                <Option v-for="item in statusesList" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </FormItem>
            <FormItem :label="$t('codekey_manage_add_remark')">
              <Input v-model="formItem.remark" style="width:300px" type="textarea" :autosize="{minRows: 2,maxRows: 5}" :placeholder="$t('codekey_manage_add_remark_placeholder')" />
            </FormItem>
          </Form>
        </div>
        <div slot="footer">
          <Button @click="cancel">{{ $t('codekey_manage_cancel') }}</Button>
          <Button type="primary" @click="save">{{ $t('codekey_manage_save') }}</Button>
        </div>
      </Modal> -->
      <!-- <Modal
          v-model="rechargeModal"
          :title="$t('codekey_manage_recharge')"
          v-if="curData"
      >
        <Form :label-width="100" >
          <FormItem :label="$t('codekey_manage_recharge_category') + ':'">
            <div>{{ curData.category ? curData.category.name : '不限'}} - {{curData.secondCategory ? curData.secondCategory.name : '不限'}}</div>
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_company') + ':'" >
            <div>{{ curData.site.name }}</div>
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_timelong') + ':'" >
            <div>{{this.timelongsObj[curData.timelong]}}</div>
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_count') + ':'" >
            <div>
              <Input v-model="rechargeAmount" :placeholder="$t('codekey_manage_add_count_placeholder')" style="width: 300px" />
            </div>
          </FormItem>
        </Form>
        <div slot="footer">
          <Button @click="cancelRecharge">{{ $t('codekey_manage_cancel') }}</Button>
          <Button type="primary" @click="saveRecharge">{{ $t('codekey_manage_save') }}</Button>
        </div>
      </Modal> -->
      <div v-if="addUserModal">
        <AddUser :dataDeatil="curData" @success="addSuccess" @close="closeAdduser" :isSuper="true"></AddUser>
      </div>
    </div>
    <!-- 新增激活码授权抽屉 -->
    <el-drawer :size="800" :visible.sync="addCategory">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ $t('codekey_manage_add') }}</div>
        <div class="drawer-title-btns">
          <Button @click="cancel" style="margin-right: 10px;">{{ $t('codekey_manage_cancel') }}</Button>
          <Button type="primary" @click="save">{{ $t('codekey_manage_save') }}</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <Form ref="formValidate" :model="formItem" :rules="ruleValidate" :label-width="100" >
          <FormItem :label="$t('codekey_manage_add_first_category')">
            <div class="category-list">
              <div :class="formItem.firstCategory == 0 ? 'active' : ''" :key="0 + 'first'" @click="changeFirstCategory(null)">不限</div>
              <div :class="formItem.firstCategory == item.id ? 'active' : ''" v-for="item in firstCategoryList" :key="item.id + 'first'" @click="changeFirstCategory(item)">{{item.name}}</div>
            </div>
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_second_category')" >
            <div class="category-list">
              <div :class="formItem.secondCategory == 0 ? 'active' : ''" :key="0 + 'category'" @click="changeSecondCategory(null)">不限</div>
              <div :class="formItem.secondCategory == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'category'" @click="changeSecondCategory(item)">{{item.name}}</div>
            </div>
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_company')" prop="company">
            <!--          <Select v-model="formItem.company" style="width:300px">-->
            <!--            <Option v-for="item in companyList" :value="item.value" :key="item.value">{{ item.label }}</Option>-->
            <!--          </Select>-->
            <el-select
                v-model="formItem.company"
                filterable
                remote
                :remote-method="(query) => siteList(query)"
                style="width:300px;"
                class="company-h"
            >
              <el-option
                  v-for="item in companyList"
                  :key="item.id"
                  :label="item.name + `(id:${item.id})`"
                  :value="item.id">
              </el-option>
            </el-select>
          </FormItem>
          <!--          <FormItem :label="$t('codekey_manage_add_price')" prop="price">-->
          <!--            <div class="price">-->
          <!--              <Input v-model="formItem.price" type="number" :disabled="!priceStatus" placeholder="请输入一码单价(元)" style="width: 300px;" @on-blur="changePrice" />-->
          <!--              <span @click="allowChangePrice">{{ $t('codekey_manage_add_price_change') }}</span>-->
          <!--            </div>-->

          <!--          </FormItem>-->
          <FormItem :label="$t('codekey_manage_add_count')" prop="num">
            <Input v-model="formItem.num" type="number" :placeholder="$t('codekey_manage_add_count_placeholder')" style="width: 300px" />
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_timelong')" prop="timelong">
            <Select v-model="formItem.timelong" style="width:300px">
              <Option v-for="item in timelongsList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_status')" prop="status">
            <Select v-model="formItem.status" style="width:300px">
              <Option v-for="item in statusesList" :value="item.value" :key="item.value">{{ item.label }}</Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_remark')">
            <Input v-model="formItem.remark" style="width:300px" type="textarea" :autosize="{minRows: 2,maxRows: 5}" :placeholder="$t('codekey_manage_add_remark_placeholder')" />
          </FormItem>
        </Form>
      </div>
    </el-drawer>
    <!-- 充值抽屉 -->
    <el-drawer :size="800" :visible.sync="rechargeModal" v-if="curData">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ $t('codekey_manage_recharge') }}</div>
        <div class="drawer-title-btns">
          <Button @click="cancelRecharge" style="margin-right: 10px;">{{ $t('codekey_manage_cancel') }}</Button>
          <Button type="primary" @click="saveRecharge">{{ $t('codekey_manage_save') }}</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <Form :label-width="100" >
          <FormItem :label="$t('codekey_manage_recharge_category') + ':'">
            <div>{{ curData.category ? curData.category.name : '不限'}} - {{curData.secondCategory ? curData.secondCategory.name : '不限'}}</div>
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_company') + ':'" >
            <div>{{ curData.site.name }}</div>
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_timelong') + ':'" >
            <div>{{this.timelongsObj[curData.timelong]}}</div>
          </FormItem>
          <FormItem :label="$t('codekey_manage_add_count') + ':'" >
            <div>
              <Input v-model="rechargeAmount" :placeholder="$t('codekey_manage_add_count_placeholder')" style="width: 300px" />
            </div>
          </FormItem>
        </Form>
      </div>
    </el-drawer>
  </div>

</template>

<script>
import util from '@/utils/tools.js';
import ActivationList from './components/detail/activationList'
import AddUser from './components/addUser';
export default {
  name: "list",
  data(){
    return {
      keyword:'',
      rowDetail:{},
      columns: [
        {
          title: 'ID',
          key: 'id',
          width:80,
          fixed:"left"
        },
        {
          title: this.$t('codekey_manage_table_name'),
          minWidth:200,
          fixed:"left",
          render: (h, params) => {
            return h('router-link', {
              props: {
                to: {
                  name: 'site/detail',
                  params: {
                    id: params.row.site.id,
                    tab:'user'
                  }
                }
              },
              style:{
                color:'#2db7f5',
              }
            }, params.row.site.name + ' (ID:' + params.row.site.id +') ');
          }
        },
        {
          title: this.$t('codekey_manage_table_category'),
          minWidth:100,
          render: (h, params) => {
            // return h('div', {}, (params.row.category ? params.row.category.name : '不限') + '-' + (params.row.secondCategory ? params.row.secondCategory.name : '不限'));
            return util.tableColumnTooltip(h,(params.row.category ? params.row.category.name : '不限') + '-' + (params.row.secondCategory ? params.row.secondCategory.name : '不限'));
          }
        },
        {
          title: this.$t('codekey_manage_table_timelong'),
          key: 'timelong',
          minWidth:100,
          render: (h, params) => {
            return h('div', {}, this.timelongsObj[params.row.timelong]);
          }
        },
        {
          title: '剩余个数',
          key: 'amount',
          minWidth:100,
          render: (h, params) => {
            return h('div', {}, params.row.amount - params.row.count);
          }
        },
        {
          title: this.$t('codekey_list_active_user'),
          key: 'used_count',
          minWidth:100,
          render: (h, params) => {
            return h('div', {}, params.row.used_count);
          }
        },
        {
          title: this.$t('codekey_list_unactive_user'),
          key: 'timelong',
          minWidth:100,
          render: (h, params) => {
            return h('div', {}, params.row.count - params.row.used_count);
          }
        },
        {
          title: this.$t('codekey_manage_table_active_rate'),
          key: 'rate',
          minWidth:100,
          render: (h, params) => {
            return h('div', {}, params.row.count > 0 && params.row.used_count > 0 ? Math.floor(params.row.used_count/params.row.count*100) + '%' : '0%');
          }
        },
        {
          title: this.$t('codekey_manage_add_status'),
          key: 'status',
          minWidth:100,
          render: (h, params) => {
            return h('div', {}, this.statusesObj[params.row.status]);
          }
        },
        {
          title: this.$t('codekey_manage_create_time'),
          key: 'created_at',
          minWidth:130,
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('codekey_manage_update_time'),
          key: 'updated_at',
          minWidth:130,
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.updated_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: '备注',
          key: 'desc',
          minWidth:130,
          render: (h, para) => {
            return util.tableColumnTooltip(h,para.row.desc);
          }
        },
        {
          title: this.$t('codekey_manage_op'),
          key: 'action',
          width: 280,
          fixed:"right",
          // align: 'center',
          render: (h, params) => {
            return h('div', [
              // h("Button",{
              //   props:{
              //     type:"primary",
              //   },
              //   style: {
              //     marginRight: '5px'
              //   },
              //   on:{
              //     click:()=>{
              //       this.ViewDetails(params.row);
              //     }
              //   }
              // },"查看明细"),
              h('Button', {
                props: {
                  type: 'primary',
                  disabled:params.row.status == 2 ? true : false
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.recharge(params.row)
                  }
                }
              }, this.$t('codekey_manage_recharge')),
              h('Button', {
                props: {
                  type: 'primary',
                  disabled:params.row.status == 2 ? true : false
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.curData = params.row;
                    this.addUser(params.row)
                  }
                }
              }, '添加成员'),
              h('el-select', {
                  props:{
                    transfer: true,//不受表格高度影响,
                    placeholder:this.$t('codekey_manage_more'),
                    size: 'mini',
                    clearable:true,
                    value:'',
                  },
                  style:{
                    width:'75px',
                    marginLeft:'5px'
                  },
                  on: {
                    'change':(event) => {
                      if(event == 'open'){  //启用
                        this.curData = params.row;
                        this.changeDataStatus(1);
                      }else if(event == 'close') {  //禁用
                        this.curData = params.row;
                        this.changeDataStatus(2);
                      }else if(event==="查看明细"){
                        this.ViewDetails(params.row);
                      }
                    }
                  },
                },
                [
                  h('el-option',{
                    props: {
                      value: 'open'
                    }
                  },this.$t('codekey_manage_open')),
                  h('el-option',{
                    props: {
                      value: 'close'
                    }
                  },this.$t('codekey_manage_disable')),
                  h('el-option',{
                    props: {
                      value: '查看明细'
                    }
                  },"查看明细"),
                ])
            ]);
          }
        }
      ],
      dataList: [],
      total:0,
      page:1,
      pageSize:10,
      addCategory:false, //新增激活分类弹窗
      formItem:{
        firstCategory:'',
        secondCategory:'',
        company:'',
        // price:'',
        num:'',
        timelong:'',  //有效时间
        status:'',  //状态
        remark:'',  //备注
      },
      ruleValidate: {
        company: [
          { required: true, message: this.$t('codekey_manage_company_message'), trigger: 'blur' }
        ],
        // price: [
        //   { required: true, message: this.$t('codekey_manage_price_message'), trigger: 'blur' },
        // ],
        num: [
          { required: true, message: this.$t('codekey_manage_add_count_placeholder'), trigger: 'blur' },
        ],
        timelong: [
          { required: true, message: this.$t('codekey_manage_timelong_message'), trigger: 'blur' },
        ],
        status: [
          { required: true, message: this.$t('codekey_manage_status_message'), trigger: 'blur' },
        ],
      },
      firstCategoryList:[], //一级分类列表
      secondCategoryList:[],  //二级分类列表
      companyList:[], //组织列表
      statusesObj:{},
      statusesList:[],
      timelongsObj:{},
      timelongsList:[],
      priceStatus:true, //一码单价是否可修改的状态
      rechargeModal:false,
      curData:'',
      rechargeAmount:'',
      tabList:[
        {
          name:'激活码授权管理',
          id:1
        },
        {
          name:'激活码明细查询',
          id:2
        },
      ],
      curTabId:1,
      addUserModal:false,
    }
  },
  created(){
    this.curTabId=this.$route.query.tabId || 1;
    this.rowDetail={id:this.$route.query.cdkey};
    this.getCategory(); //获取分类
    this.cdkeyForm(); //获取时长与状态
    this.siteList();  //获取组织
    this.getList(); //获取激活码列表
  },
  components:{
    ActivationList,
    AddUser
  },
  methods:{
    changeTab(data){
      this.rowDetail={}
      this.curTabId = data.id;
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.getList();
    },
    add(){  //新增激活分类
      this.addCategory = true;
      this.formItem = {
        firstCategory:0,//this.firstCategoryList[0].id,
        secondCategory:0,//this.secondCategoryList[0].id,
        company:'',
        price:'',
        num:'',
        timelong:'',  //有效时间
        status:'1',  //状态
        remark:'',  //备注
        site_id:-1,
        user_id:-1,
      };
      this.secondCategoryList = [];
      this.priceStatus = true;
    },
    getCategory(){
      let params = {
        site_id:-1,
        type:0
      }
      this.api.dataset.categoryForm(params).then((res)=>{
        this.firstCategoryList = res.categoryFirstList;

        this.formItem.firstCategory = 0;//this.firstCategoryList[0].id;

        let params = {
          category_id:this.formItem.firstCategory,
          site_id:-1
        };
        this.getSecondCategory(params);
      })
    },
    getSecondCategory(data){
      if (data.category_id > 0){
        data.type = 0;
        this.api.dataset.categoryForm(data).then((res)=>{
          this.secondCategoryList = res.categorySecondList;
          this.formItem.secondCategory = 0;//this.secondCategoryList[0].id;
        })
      }else{
        this.secondCategoryList = [];
        this.formItem.secondCategory = 0;
      }

    },
    changeFirstCategory(data){
      this.formItem.firstCategory = 0;
      if (data){
        this.formItem.firstCategory = data.id;
      }

      let params = {
          category_id:this.formItem.firstCategory,
          site_id:'-1'
        };
      this.getSecondCategory(params);
    },
    changeSecondCategory(data){
      this.formItem.secondCategory = 0;
      if (data){
        this.formItem.secondCategory = data.id;
      }
    },
    cancel(){
      this.addCategory = false;
    },
    cdkeyForm(){
      this.api.codekey.cdkeyForm().then((res)=>{
        this.statusesObj = res.statuses;
        this.statusesList = [];
        for(let name in res.statuses){
          this.statusesList.push({
            label:res.statuses[name],
            value:name
          })
        }

        this.timelongsObj = res.timelongs;
        this.timelongsList = [];
        for(let name in res.timelongs){
          this.timelongsList.push({
            label:res.timelongs[name],
            value:name
          })
        }
      })
    },
    siteList(data){
      let params = {
        keyword:data,
        site_id:-1,
        user_id:-1,
      };
      this.api.site.siteList(params).then((res)=>{
        this.companyList = res.list;
        console.log(this.companyList,'this.companyList')
      })
    },
    changePrice(){
      if(this.formItem.price){
        this.priceStatus = false;
      }
    },
    allowChangePrice(){ //修改单价
      this.priceStatus = true;
    },
    save(){
      this.$refs['formValidate'].validate((valid) => {
        if(valid){
          let params = {
            desc:this.formItem.remark,
            category_id:this.formItem.firstCategory,
            second_category_id:this.formItem.secondCategory,
            amount:this.formItem.num,
            timelong:this.formItem.timelong,
            status:this.formItem.status,
            // price:this.formItem.price,
            site_id:this.formItem.company
          }
          this.api.codekey.cdkeyCreate(params).then((res)=>{
            this.addCategory = false;
            this.getList();
          })
        }
      })

    },
    getList(){  //获取列表数据
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword:this.keyword,
        site_id:-1,
        user_id:-1,
      };
      this.api.codekey.cdkeyList(params).then((res)=>{
        this.dataList = res.list;
        this.total = Number(res.count);
      })
    },
    ViewDetails(row){
      this.rowDetail=row;
      this.curTabId=2;
    },
    recharge(data){ //充值
      this.curData = data;
      this.rechargeModal = true;
      this.rechargeAmount = '';
    },
    cancelRecharge(){ //取消充值
      this.rechargeModal = false;
    },
    saveRecharge(){ //保存充值
      let params = {
        id:this.curData.id,
        add_amount:this.rechargeAmount,
        site_id:-1,
        user_id:-1,
      };
      this.api.codekey.cdkeyUpdate(params).then((res)=>{
        this.rechargeModal = false;
        this.getList();
      })
    },
    changeDataStatus(status){ //启用
      let params = {
        id:this.curData.id,
        status:status,
        site_id:-1,
        user_id:-1,
      };
      this.api.codekey.cdkeyUpdate(params).then((res)=>{
        this.$Message.success(this.$t('codekey_manage_op_success'));
        this.$nextTick(()=>{
          this.getList();
        });
      })
    },
    addUser(){
      this.addUserModal = true;
    },
    addSuccess(){
      this.page = 1;
      this.getList();
      this.addUserModal = false;
    },
    closeAdduser(){
      this.addUserModal = false;
      this.getList();
    }
  }
}
</script>

<style scoped lang="scss">
.super-list{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .super-list-tab{
    margin-bottom: 20px;
    display: inline-block;

    .super-list-tab-list{
      height: 38px;
      line-height: 38px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      >div{
        width: 150px;
        text-align: center;
        border-right: 1px solid #dcdee2;
        cursor: pointer;
      }
      >div:last-child{
        border-right:none;
      }
      .active{
        color:#FFFFFF;
        background-color: #2d8cf0;
      }
    }
  }

  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .top >button{
    margin-right: 20px;
  }
  .search{
    //margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
  .table{
    margin-top: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }

}

.category-list{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  >div{
    margin-right: 10px;
    padding: 0px 10px;
    cursor: pointer;
  }
  >div.active{
    background-color: #2d8cf0;
    color:#FFFFFF;
    border-radius: 4px;
  }
}
.price >span{
  margin-left: 10px;
  color:#2d8cf0;
  cursor: pointer;
}
</style>
<style lang="scss">
.company-h .el-input__inner{
  height: 33px !important;
}
.super-list .table .el-input--mini .el-input__inner{
  height: 31px !important;
  line-height: 31px !important;
}
</style>

